import { createHmac, createHash } from 'crypto'
import { Buffer } from 'buffer'
import moment from 'moment'
import { load } from 'cheerio'
const { v4 } = require('uuid')

const BASE_URL = 'https://fswncus4rf.execute-api.ap-southeast-2.amazonaws.com/prod'
const HMAC_SECRET_KEY = 'e54e4bc2-5697-414e-9f6c-4c192f7b25f6'
const VIC_BEACH_STADIUM_ID = 8305

export const getAuthHeader = (queryString: string) => {
  const verification1 = v4().replace(/-/g, '')
  const verification2 = moment.utc().unix().toString()
  const md5query = Buffer.from(createHash('md5').update(Buffer.from(queryString)).digest()).toString('base64')
  const firstPart = md5query + verification1 + verification2
  const hmac = Buffer.from(createHmac('sha256', HMAC_SECRET_KEY).update(firstPart).digest()).toString('base64')
  return `ska ${hmac}:${verification1}:${verification2}`
}

interface IRequestOptions {
  authRequest?: boolean
  responseType?: 'json' | 'text'
}

export const request = async <T>(path: string, queryString: string, options: IRequestOptions = { authRequest: true, responseType: 'json' }): Promise<T> => {
  const responseType = options.responseType ?? 'json'
  const authRequest = options.authRequest ?? true
  const headers = authRequest ? { auth: getAuthHeader(queryString) } : {} as any
  const resp = await fetch(`${BASE_URL}${path}?${queryString}`, { headers })
  const response = responseType === 'json' ? await resp.json() : await resp.text()
  return response
}

export interface SearchTeamsResponse {
  authorized: boolean
  data: {
    stadiumId: number
    stadiumName: string
    stadiumAddress: string
    stadiumPhone: string
    stadiumEmail: string
    stadiumWebsite: string
    stadiumFax: string
    stadiumHomeUrl: string
    stadiumLogoUrl: string
    teams: {
      teamId: number
      teamName: string
      gradeId: number
      gradeName: string
      sportId: number
      sportName: string
    }[]
  }[]
}
export type ISearchTeamsReturnValue = (SearchTeamsResponse["data"][0]["teams"][0] & { stadiumId: number })[]
export const searchTeam = async (team: string): Promise<ISearchTeamsReturnValue> => {
  const stadiums = await request<SearchTeamsResponse>('/api/getTeams.php', `filterString=${encodeURIComponent(team)}`)
  const stadium = stadiums.data.find(stadium => stadium.stadiumId === VIC_BEACH_STADIUM_ID)
  if (!stadium) return []
  return stadium.teams.map(team => ({
    ...team,
    stadiumId: stadium.stadiumId
  }))
}

export interface GetTeamFixtureResponse {
  authorized: boolean
  data: {
    teamId: number
    teamName: string
    matches: {
      date: string
      round: string
      opponentName: string
      court?: string
    }[]
  }
}
export type IGetTeamFixtureReturnValue = GetTeamFixtureResponse["data"]
export const getTeamFixture = async (teamId: number, stadiumId: number, gradeId: number, sportId: number): Promise<IGetTeamFixtureReturnValue | undefined> => {
  const queryString = `team-id=${teamId}&stadium-id=${stadiumId}&grade-id=${gradeId}&sport-id=${sportId}&view=fixture`
  const apiFixture = (await request<GetTeamFixtureResponse>('/api/getTeamData.php', queryString)).data

  // Scrape website data for the court
  const html = await request(`/vic--geelong-vic-beach/stadium-team-fixture.php?sp=${sportId}&g=${gradeId}&s=${VIC_BEACH_STADIUM_ID}&t=${teamId}&embedded=`, '', { authRequest: false, responseType: 'text' }) as string

  // The court is in a <table> with id 'ladder' and is the 6th td element in the row matching each match time
  const $ = load(html)
  const dates = $('table#ladder tr td:nth-child(4)').toArray().map(td => $(td).text().trim())
  const times = $('table#ladder tr td:nth-child(5)').toArray().map(td => $(td).text().trim())
  const courts = $('table#ladder tr td:nth-child(6)').toArray().map(td => $(td).text().trim())

  // Normalise times against courts
  const courtTimes = dates.map((date, i) => ({
    date: moment(`${date} ${times[i]}`, 'DD MMM YY h:mm A'),
    court: courts[i]
  }))

  // Assign courts to each match
  apiFixture.matches = apiFixture.matches.map((match) => {
    const courtTime = courtTimes.find(ct => ct.date.isSame(moment.tz(match.date, 'Australia/Melbourne')))
    return {
      ...match,
      court: courtTime?.court
    }
  })


  return apiFixture
}

export interface GetTeamLadderResponse {
  authorized: boolean
  data: {
    id: number
    name: string
    sportId: number
    sportName: string
    message: string
    ladder: {
      position: number
      teamId: number
      teamName: string
      data: {
        played: number
        wins: number
        losses: number
        draws: number
        byes: number
        ff: number
        fa: number
        ptsFor: number
        ptsAgainst: number
        percentage: number
        bonusPoints: number
        totalPoints: number
      }
    }[]
  }
}
export type IGetTeamLadderReturnValue = GetTeamLadderResponse["data"]
export const getTeamLadder = async (teamId: number, stadiumId: number, gradeId: number, sportId: number): Promise<IGetTeamLadderReturnValue | undefined> => {
  const queryString = `team-id=${teamId}&stadium-id=${stadiumId}&grade-id=${gradeId}&sport-id=${sportId}&view=ladder`
  return (await request<GetTeamLadderResponse>('/api/getTeamData.php', queryString)).data
}

export interface GetTeamResultsResponse {
  authorized: boolean
  data: {
    teamId: number
    teamName: string
    message: string
    results: {
      date: string
      round: string
      opponentName: string
      opponentId: number
      outcome: string
      score: number
      opponentScore: number
      points: number
    }[]
  }
}
export type IGetTeamResultsReturnValue = GetTeamResultsResponse["data"]
export const getTeamResults = async (teamId: number, stadiumId: number, gradeId: number, sportId: number): Promise<IGetTeamResultsReturnValue | undefined> => {
  const queryString = `team-id=${teamId}&stadium-id=${stadiumId}&grade-id=${gradeId}&sport-id=${sportId}&view=results`
  return (await request<GetTeamResultsResponse>('/api/getTeamData.php', queryString)).data
}

