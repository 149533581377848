import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Grid,
  Drawer,
  useMediaQuery,
  BottomNavigation,
  BottomNavigationAction,
  List,
  ListItem,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  CircularProgress,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import ShareIcon from '@mui/icons-material/SaveAlt';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import EventIcon from '@mui/icons-material/Event';
import * as colors from '@mui/material/colors';
import TeamCard from '../components/TeamCard';
import AppBarComponent from '../components/AppBar';
import moment from 'moment';
import { createEvents, EventAttributes } from 'ics';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/useStores';
import useUpcomingMatchesQuery, { sortAndGroupUpcomingMatches } from '../hooks/useUpcomingMatchesQuery';
import useHourlyWeatherQuery from '../hooks/useHourlyWeatherQuery';

const version = require('../../package.json').version;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh'
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
  drawerContent: {
    paddingTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  drawerList: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  drawerBottomItem: {
    marginTop: 'auto',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    paddingTop: 64,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    minHeight: '100%'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  bottomNavContainer: {
    position: 'sticky',
    bottom: 0,
    paddingBottom: 'env(safe-area-inset-bottom, 20px)',
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  text: {
    color: theme.palette.text.primary,
  },
  refText: {
    color: colors.green[500],
  },
  byeText: {
    color: colors.red[300],
  },
  yellowText: {
    color: colors.yellow[800],
  },
  upcoming: {
    marginTop: theme.spacing(2),
    textAlign: 'left'
  },
  divider: {
    marginTop: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const App = () => {
  const navigate = useNavigate()
  const { app } = useStores()
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [open, setOpen] = useState(false);
  const [navSelected, setNavSelected] = useState('teams');
  const [dialogOpen, setDialogOpen] = useState(false);

  const { data: upcomingMatches, isLoading: isUpcomingMatchesLoading } = useUpcomingMatchesQuery(app.teams)
  const { data: hourlyWeather } = useHourlyWeatherQuery()

  const sortedMatches = upcomingMatches ? sortAndGroupUpcomingMatches(upcomingMatches) : {}

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  const handleBottomNavChange = (event: any, newValue: any) => {
    setNavSelected(newValue);
    window.scrollTo(0, 0);
  };

  const matchHourlyTimestepToMatches = () => {
    if (!hourlyWeather || !upcomingMatches) return;

    Object.entries(sortedMatches).forEach(([round, matches]) => {
      matches.forEach((match) => {
        const matchDate = moment.tz(match.date, 'Australia/Melbourne')

        // Match the match date to the weather date.
        // If the match date is after the 45th minute of the hour, use the next hour's weather
        const matchWeather = hourlyWeather.find((h: any) => {
          const weatherDate = moment.utc(h.time).tz('Australia/Melbourne')
          if (matchDate.isSame(weatherDate, 'hour')) {
            return matchDate.isBefore(weatherDate.add(45, 'minutes'))
          } else if (matchDate.clone().add(1, 'hour').isSame(weatherDate, 'hour')) {
            return true
          }

          return false
        })

        if (matchWeather) {
          match.temp = matchWeather.temp
          match.temp_feels_like = matchWeather.temp_feels_like
        }
      })
    })
  }

  const renderContent = () => {
    matchHourlyTimestepToMatches()
    switch (navSelected) {
      case 'teams':
        if (app.teams.length === 0) return (
          <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.text} style={{ marginTop: 12 }}>Add a team with the + button</Typography>
            </Grid>
          </Grid>
        );
        return (
          <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
            {app.teams.map((team) => (
              <Grid item xs={12}>
                <TeamCard team={team} />
              </Grid>
            ))}
          </Grid>
        );
      case 'upcoming':
        return isUpcomingMatchesLoading
          ? (
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          ) : (
            <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start" >
              {Object.entries(sortedMatches).map(([round, matches], index) => (
                <>
                  <Grid item xs={12} className={classes.upcoming} style={{ textAlign: 'center' }}>
                    <Typography variant="body1" style={{ fontSize: 24, fontWeight: 'bold' }} className={classes.yellowText}>{`Round ${round}`}</Typography>
                  </Grid>
                  <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                    {matches.map((game, gameIndex) => game.type === 'match'
                      ? (
                        <>
                          <Grid item xs={12} className={classes.upcoming}>
                            <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                              <Grid item xs={8} style={{ textAlign: 'left' }}>
                                <Typography variant="body1" className={classes.text}>{moment(game.date).format('h:mm a, dddd MMMM D, YYYY')}</Typography>
                              </Grid>
                              <Grid item xs={4} style={{ textAlign: 'right' }}>
                                <Typography variant="body1" className={classes.text}>{`${game.court ?? ''}${game.temp_feels_like ? ` | ${game.temp_feels_like}°C` : ''}`}</Typography>
                              </Grid>
                            </Grid>
                            <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                              <Grid item xs={5} style={{ textAlign: 'left' }}>
                                <Typography variant="h6" className={classes.text} style={{ fontWeight: 'bold' }}>{game.teamName}</Typography>
                              </Grid>
                              <Grid item xs={2} style={{ textAlign: 'center' }}>
                                <Typography variant="h6" className={classes.text} style={{ fontWeight: 'bold' }}>vs</Typography>
                              </Grid>
                              <Grid item xs={5} style={{ textAlign: 'right' }}>
                                <Typography variant="h6" className={classes.text} style={{ fontWeight: 'bold' }}>{game.opponentName}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ) : (game.type === 'ref' ? (
                        <Grid item xs={12} className={classes.upcoming}>
                          <Typography variant="body1" className={classes.text}>{`${moment(game.date).format('h:mm a, dddd MMMM D, YYYY')}${game.temp ? `  |  ${game.temp}°C (${game.temp_feels_like}°C)` : ''}`}</Typography>
                          <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={5} style={{ textAlign: 'left' }}>
                              <Typography variant="h6" className={classes.refText} style={{ fontWeight: 'bold' }}>Referee</Typography>
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: 'center' }}>
                              <Typography variant="h6" className={classes.refText} style={{ fontWeight: 'bold' }}>on</Typography>
                            </Grid>
                            <Grid item xs={5} style={{ textAlign: 'right' }}>
                              <Typography variant="h6" className={classes.refText} style={{ fontWeight: 'bold' }}>{game.court}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item xs={12} className={classes.upcoming}>
                          <Typography variant="body1" className={classes.text}>{moment(game.date).format('dddd MMMM D, YYYY')}</Typography>
                          <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={5} style={{ textAlign: 'left' }}>
                              <Typography variant="h6" className={classes.byeText} style={{ fontWeight: 'bold' }}>{game.teamName}</Typography>
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: 'center' }}>
                              <Typography variant="h6" className={classes.byeText} style={{ fontWeight: 'bold' }}></Typography>
                            </Grid>
                            <Grid item xs={5} style={{ textAlign: 'right' }}>
                              <Typography variant="h6" className={classes.byeText} style={{ fontWeight: 'bold' }}>Bye</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    ))}
                    <Grid item xs={12} className={classes.divider}><Divider variant="fullWidth" /></Grid>
                  </Grid>
                </>
              ))}
            </Grid>
          );
      default:
        return <></>
    }
  }

  const getRightAppBarIcon = () => {
    switch (navSelected) {
      case 'teams':
        return <AddIcon />
      case 'upcoming':
        return <ShareIcon />
      default:
        return <></>
    }
  }

  const handleRightAppBarOnClick = () => {
    switch (navSelected) {
      case 'teams':
        navigate('/search')
        break;
      case 'upcoming':
        const events = app.teams.map(team => team.getNewCalendarFixtures()).flat().filter(e => e) as EventAttributes[];

        // If there are no new events, open the dialog and return early
        if (events.length === 0) {
          setDialogOpen(true);
          return;
        }

        const { error, value } = createEvents(events);

        if (error) {
          console.log('Failed to create calendar events:', error);
          return;
        }

        app.teams.forEach(team => team.saveAddedCalendarEvents(events))

        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

        if (isIOS) {
          const url = `data:text/calendar;charset=utf8,${encodeURIComponent(value!)}`;
          window.location.href = url;
        } else {
          const blob = new Blob([value!], { type: "text/calendar;charset=utf-8" });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'fixtures.ics';
          link.click();
        }

        break;
      default:
        break;
    }
  }

  return (
    <div className={classes.root}>
      <AppBarComponent
        LeftIcon={<MenuIcon />}
        onLeftIconClick={handleToggleDrawer}
        RightIcon={getRightAppBarIcon()}
        onRightIconClick={handleRightAppBarOnClick}
      >
        VicBeach Geelong Teams
      </AppBarComponent>
      <Drawer
        className={classes.drawer}
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <div className={classes.drawerContent}>
          <List className={classes.drawerList}>
            <ListItem>
              <ListItemButton onClick={() => { navigate('/ref/add-times') }}>Add ref times</ListItemButton>
            </ListItem>
            <ListItem className={classes.drawerBottomItem}>
              <ListItemText><div style={{ color: 'grey' }}>{`App version: ${version}`}</div></ListItemText>
            </ListItem>
          </List>
        </div>
      </Drawer>
      <main className={`${classes.content} ${open && !isMobile ? classes.contentShift : ''}`}>
        {renderContent()}
      </main>
      <div className={classes.bottomNavContainer}>
        <BottomNavigation value={navSelected} showLabels onChange={handleBottomNavChange}>
          <BottomNavigationAction label="Teams" value="teams" icon={<RecentActorsIcon />} />
          {app.teams.length && <BottomNavigationAction label="Upcoming" value="upcoming" icon={<EventIcon />} />}
        </BottomNavigation>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"No new fixtures to add to the calendar"}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default observer(App);
