import React, { useState } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Typography, Divider, Grid, CircularProgress, colors } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EventIcon from '@mui/icons-material/Event';
import AssessmentIcon from '@mui/icons-material/Assessment';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import HistoryIcon from '@mui/icons-material/History';
import AppBarComponent from '../components/AppBar';
import moment from 'moment';
import useTeamQuery from '../hooks/useTeamQuery';
import { observer } from 'mobx-react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.default,
    flexDirection: 'column',
    minHeight: '100vh'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    marginTop: theme.spacing(6),
  },
  primaryText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  text: {
    color: theme.palette.text.primary,
  },
  yellowText: {
    color: colors.yellow[800],
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  bottomNavContainer: {
    position: 'sticky',
    bottom: 0,
    paddingBottom: 'env(safe-area-inset-bottom, 20px)',
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  divider: {
    backgroundColor: theme.palette.text.primary
  }
}));

const TeamPage = () => {
  const { teamId } = useParams()
  const { state } = useLocation()
  const classes = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = useState('summary');

  const { data: team, isLoading } = useTeamQuery(teamId, state?.teamName)

  const nextGame = team?.getNextGame()
  const recentResult = team?.results?.results?.filter(r => r.date).slice(-1)?.[0]
  const streak = team?.getStreak()

  const handleBottomNavChange = (event: any, newValue: any) => {
    setValue(newValue);
    window.scrollTo(0, 0)
  };

  const SummaryContent = () => {
    return (
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontSize: 24 }} className={classes.primaryText}>Ladder</Typography>
          <Typography variant="body1" style={{ fontSize: 24 }} className={classes.secondaryText}>{`Position: ${team!.getLadderPosition() ?? 'N/A'}`}</Typography>
          <Typography variant="body1" style={{ marginTop: '12px', fontSize: 14 }} className={classes.secondaryText}>{`Leaders: ${team?.ladder?.ladder?.[0]?.teamName ?? 'N/A'}`}</Typography>
        </Grid>
        <Grid item xs={12}><Divider className={classes.divider} variant="fullWidth" /></Grid>
        {nextGame && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ fontSize: 24 }} className={classes.primaryText}>Next Match</Typography>
              <Typography variant="body1" style={{ fontSize: 24 }} className={classes.secondaryText}>{moment(nextGame.date).format('h:mm a')}</Typography>
              <Typography variant="body1" style={{ fontSize: 24 }} className={classes.secondaryText}>{moment(nextGame.date).format('dddd MMMM D')}</Typography>
              <Typography variant="body1" style={{ fontSize: 20, fontWeight: 'bold' }} className={classes.text}>{nextGame.opponentName}</Typography>
            </Grid>
            <Grid item xs={12}><Divider className={classes.divider} variant="fullWidth" /></Grid>
          </>
        )}
        {recentResult && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ fontSize: 24 }} className={classes.primaryText}>Recent Result</Typography>
              <Grid container spacing={0} direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Grid item xs={6} style={{ textAlign: 'left' }}><Typography variant="body1" style={{ fontSize: 20, fontWeight: ['Win', 'WOF'].includes(recentResult.outcome) ? 'bold' : 0 }} className={classes.text}>{team.team.teamName}</Typography></Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}><Typography variant="body1" style={{ fontSize: 20, fontWeight: ['Win', 'WOF'].includes(recentResult.outcome) ? 'bold' : 0 }} className={classes.text}>{recentResult.score}</Typography></Grid>
                <Grid item xs={6} style={{ textAlign: 'left' }}><Typography variant="body1" style={{ fontSize: 20, fontWeight: ['Win', 'WOF'].includes(recentResult.outcome) ? 0 : 'bold' }} className={classes.text}>{recentResult.opponentName}</Typography></Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}><Typography variant="body1" style={{ fontSize: 20, fontWeight: ['Win', 'WOF'].includes(recentResult.outcome) ? 0 : 'bold' }} className={classes.text}>{recentResult.opponentScore}</Typography></Grid>
              </Grid>
              <Typography variant="body1" style={{ marginTop: '8px', fontSize: 14 }} className={classes.secondaryText}>{moment(recentResult.date).format('dddd MMMM D')}</Typography>
            </Grid>
            <Grid item xs={12}><Divider className={classes.divider} variant="fullWidth" /></Grid>
          </>
        )}
        {streak && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ fontSize: 24 }} className={classes.primaryText}>Current Streak</Typography>
              <Typography variant="body1" style={{ fontSize: 24 }} className={classes.text}>{`${Math.abs(streak)} ${streak >= 0 ? `win${streak > 1 ? 's' : ''}` : `loss${streak < -1 ? 'es' : ''}`}`}</Typography>
            </Grid>
            <Grid item xs={12}><Divider className={classes.divider} variant="fullWidth" /></Grid>
          </>
        )}
      </Grid>
    );
  };

  const LadderContent = () => {
    return (
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.primaryText}>{team?.team.sportName}</Typography>
          <Typography variant="h6" className={classes.primaryText}>{team?.team.gradeName}</Typography>
        </Grid>
        <Grid item xs={1}><Typography variant="body1" style={{ fontSize: 16, fontWeight: 'bold' }} className={classes.text}>#</Typography></Grid>
        <Grid item xs={6}><Typography variant="body1" style={{ fontSize: 16, fontWeight: 'bold' }} className={classes.text}>Team</Typography></Grid>
        <Grid item xs={1}><Typography variant="body1" style={{ fontSize: 16, fontWeight: 'bold' }} className={classes.text}>W</Typography></Grid>
        <Grid item xs={1}><Typography variant="body1" style={{ fontSize: 16, fontWeight: 'bold' }} className={classes.text}>L</Typography></Grid>
        <Grid item xs={1}><Typography variant="body1" style={{ fontSize: 16, fontWeight: 'bold' }} className={classes.text}>TP</Typography></Grid>
        <Grid item xs={2}><Typography variant="body1" style={{ fontSize: 16, fontWeight: 'bold' }} className={classes.text}>%</Typography></Grid>
        {team?.ladder?.ladder?.map((l, i) => {
          const textClass = `${l.teamId}` === teamId ? classes.yellowText : classes.text
          return (
            <>
              <Grid item xs={1}><Typography variant="body1" style={{ fontSize: 16 }} className={textClass}>{`${i + 1}`}</Typography></Grid>
              <Grid item xs={6}>
                <Link to={`/teams/${l.teamId}`} state={{ teamName: l.teamName }} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Typography variant="body1" style={{ fontSize: 16 }} className={textClass}>{`${l.teamName}`}</Typography>
                </Link>
              </Grid>
              <Grid item xs={1}><Typography variant="body1" style={{ fontSize: 16 }} className={textClass}>{`${l.data.wins}`}</Typography></Grid>
              <Grid item xs={1}><Typography variant="body1" style={{ fontSize: 16 }} className={textClass}>{`${l.data.losses}`}</Typography></Grid>
              <Grid item xs={1}><Typography variant="body1" style={{ fontSize: 16 }} className={textClass}>{`${l.data.totalPoints}`}</Typography></Grid>
              <Grid item xs={2}><Typography variant="body1" style={{ fontSize: 16 }} className={textClass}>{`${l.data.percentage}`}</Typography></Grid>
            </>
          )
        })}
      </Grid>
    )
  };

  const FixtureContent = () => {
    if (!team?.fixture?.matches?.length) {
      return (
        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={12}><Typography variant="body1" className={classes.text}>No fixtured matches</Typography></Grid>
        </Grid>
      )
    }
    return (
      <Grid container spacing={0} direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={12}><Typography variant="body1" className={classes.secondaryText}>{`Round ${team?.fixture?.matches[0].round}`}</Typography></Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant="body1" style={{ fontSize: 24, fontWeight: 'bold' }} className={classes.text}>{team?.fixture?.matches[0].opponentName}</Typography>
          <Typography variant="body1" style={{ fontSize: 26 }} className={classes.secondaryText}>{moment(team?.fixture?.matches[0].date).format('h:mm a')}</Typography>
          <Typography variant="body1" style={{ fontSize: 18 }} className={classes.secondaryText}>{moment(team?.fixture?.matches[0].date).format('dddd MMMM D, YYYY')}</Typography>
        </Grid>
        {team?.fixture?.matches?.slice(1).map((f, i) => (
          <>
            <Grid item xs={12} style={{ marginTop: 24 }}></Grid>
            <Grid item xs={6} style={{ textAlign: 'left' }}><Typography variant="body1" className={classes.secondaryText}>{`Round ${f.round}`}</Typography></Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}><Typography variant="body1" className={classes.secondaryText}>{moment(f.date).format('MMMM D, YYYY')}</Typography></Grid>
            <Grid item xs={6} style={{ textAlign: 'left' }}><Typography variant="body1" style={{ fontWeight: 'bold' }} className={classes.text}>{f.opponentName}</Typography></Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}><Typography variant="body1" className={classes.text}>{moment(f.date).format('h:mm a')}</Typography></Grid>
          </>
        ))}
      </Grid>
    )
  };

  const ResultsContent = () => {
    if (!team?.results?.results?.length) {
      return (
        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={12}><Typography variant="body1" className={classes.text}>No results found</Typography></Grid>
        </Grid>
      )
    }
    return (
      <Grid container spacing={0} direction="row" justifyContent="flex-start" alignItems="flex-start">
        {Array.from(team?.results?.results).reverse().map((r, i) => (
          <>
            <Grid item xs={6} style={{ textAlign: 'left' }}><Typography variant="body1" className={classes.secondaryText}>{`Round ${r.round}`}</Typography></Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}><Typography variant="body1" className={classes.secondaryText}>{moment(r.date).format('MMMM D, YYYY')}</Typography></Grid>
            <Grid item xs={12}><Typography variant="body1" style={{ fontWeight: 'bold' }} className={classes.text}>{r.opponentName}</Typography></Grid>
            <Grid item xs={12}><Typography variant="body1" className={classes.text}>{`${r.outcome} | ${r.score} - ${r.opponentScore} | Points: ${r.points}`}</Typography></Grid>
            <Grid item xs={12} style={{ marginBottom: 24 }}></Grid>
          </>
        ))}
      </Grid>
    )
  };

  const TeamContent = () => {
    switch (value) {
      case 'summary':
        return <SummaryContent />;
      case 'ladder':
        return <LadderContent />;
      case 'fixture':
        return <FixtureContent />;
      case 'results':
        return <ResultsContent />;
      default:
        return <SummaryContent />;
    }
  };

  return (
    <div className={classes.root}>
      <AppBarComponent
        LeftIcon={<ArrowBackIcon />}
        onLeftIconClick={() => navigate(-1)}
      >
        {state.teamName ?? team?.team.teamName ?? 'Loading...'}
      </AppBarComponent>
      <main className={classes.content}>
        {isLoading
          ? <Grid item xs={12}><CircularProgress /></Grid>
          : <TeamContent />
        }
      </main>
      <div className={classes.bottomNavContainer}>
        <BottomNavigation value={value} showLabels onChange={handleBottomNavChange}>
          <BottomNavigationAction label="Summary" value="summary" icon={<RecentActorsIcon />} />
          <BottomNavigationAction label="Ladder" value="ladder" icon={<AssessmentIcon />} />
          <BottomNavigationAction label="Fixture" value="fixture" icon={<EventIcon />} />
          <BottomNavigationAction label="Results" value="results" icon={<HistoryIcon />} />
        </BottomNavigation>
      </div>
    </div>
  );
};

export default observer(TeamPage);
